import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '@app/_service';
import {Const} from "@app/_helper/const";

@Injectable({providedIn: 'root'})
export class AnonymousGuard {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggedIn) {
      const account = this.authService.accountValue;
      if (this.authService.isNeed2fa) {
        return true;
      }
      console.warn('AnonymousGuard activated!');
      this.router.navigate([`/${Const.URI_HOME}`]);
      return false;
    }
    return true;
  }
}

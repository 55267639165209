import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AlertService, AuthService} from "@app/_service";
import {first} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {Const} from "@app/_helper";

@Component({
  selector: 'app-auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  standalone: false
})
export class LoginComponent implements OnInit {

  form!: FormGroup;
  isLoading = false;

  showPassword = false;

  protected readonly Const = Const;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService
  ) {
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  protected toggleShowPassword(show: boolean): void {
    this.showPassword = !show;
  }

  protected onSubmit(): void {
    this.alertService.clear();
    if (this.form.invalid) {
      return;
    }
    if (!this.form.value.username || !this.form.value.password) {
      return;
    }
    this.isLoading = true;
    this.authService.login(this.form.value.username, this.form.value.password)
      .pipe(first())
      .subscribe({
        next: () => {
          if (this.authService.isNeed2fa) {
            console.info('need2fa');
            this.alertService.info($localize `Two-factor authentication required`, {keepAfterRouteChange: true});
            return this.router.navigateByUrl(`/${Const.URI_AUTH}/${Const.URI_AUTH_LOGIN_2FA}`);
          }
          this.alertService.info($localize `Login successful`, {keepAfterRouteChange: true});
          const returnUrl = this.route.snapshot.queryParams[Const.PARAM_RETURNURL] || `/${Const.URI_HOME}`;
          return this.router.navigateByUrl(returnUrl);
        },
        error: error => {
          this.form.reset();
          this.authService.logoutSilent();
          console.error(error);
          this.alertService.error(error.messageCustom || error);
          this.isLoading = false;
        }
      });
  }

}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '@app/_service';
import {RoleType} from "@app/_model";

@Injectable({providedIn: 'root'})
export class RoleGuard {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const roles = route.data['roles'] as RoleType[];
    if (!roles) {
      return true;
    }
    // console.debug('roles:', typeof roles, roles, typeof roles[0], roles[0], roles[0] === RoleType.Admin, roles[0] === 'ADMIN');
    if (!this.authService.isLoggedIn) {
      console.warn('RoleGuard activated!');
      return false;
    }
    const account = this.authService.accountValue;
    // console.debug('account:', account);
    // console.debug('account.roles:', account.roles);
    const filter = account?.roles?.filter(role => {
      // console.debug('role:', typeof role, role);
      return roles.includes(role);
    });
    // console.debug('filter:', filter);
    if (filter == undefined || filter.length == 0) {
      console.warn('RoleGuard activated!');
      return false;
    }
    return true;
  }
}

import {Account} from "@app/_model/account";
import {Client} from "@app/_model/client";
import {BackendData} from "@app/_model/backend.data";

export class AccountClient extends BackendData {
  account: Account;
  client: Client;
  type: AccountClientType;
  valid: string | undefined; // FIXME Range<Date>

  constructor(
    id: number,
    inserted: Date | undefined,
    insertedBy: Account | undefined,
    updated: Date | undefined,
    updatedBy: Account | undefined,
    account: Account,
    client: Client,
    type: AccountClientType,
    valid: string | undefined
  ) {
    super(id, inserted, insertedBy, updated, updatedBy);
    this.account = account;
    this.client = client;
    this.type = type;
    this.valid = valid;
  }
}

export enum AccountClientType {
  User = 'USER',
  Admin = 'ADMIN'
}

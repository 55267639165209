import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertService, AuthService} from "@app/_service";
import {first} from "rxjs";
import {Const, Util} from '@app/_helper';
import {ReCaptchaV3Service} from "ng-recaptcha";

@Component({
  selector: 'app-auth-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrl: './password-reset.component.css',
  standalone: false
})
export class PasswordResetComponent implements OnInit {

  form!: FormGroup;
  isLoading = false;

  queryParamsReaded = false;
  username?: string;
  code?: string;

  showPassword = false;
  showPasswordAgain = false;

  protected readonly Const = Const;

  errors = {
    password: $localize `Required, minimum length is 8`,
    passwordAgain: $localize `Passwords must match`
  };

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) {
  }

  public ngOnInit(): void {
    this.readQueryParams();
    this.form = this.formBuilder.group({
      username: this.username,
      code: this.code,
      password: ['', [Validators.required, Validators.minLength(8)]],
      passwordAgain: ['', Validators.required]
    }, {
      validators: Util.matchValidator('password', 'passwordAgain', 'passwordsMatch', $localize `Passwords do not match`)
    });
  }

  private readQueryParams(): void {
    this.route.queryParams
      .subscribe(params => {
        if (this.queryParamsReaded) {
          return;
        }
        this.username = params['username'];
        this.code = params['code'];
        this.form.patchValue({
          username: this.username,
          code: this.code
        });
        this.queryParamsReaded = true;
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {username: null, code: null},
          queryParamsHandling: 'merge'
        });
      })
  }

  protected toggleShowPassword(show: boolean): void {
    this.showPassword = !show;
  }

  protected toggleShowPasswordAgain(show: boolean): void {
    this.showPasswordAgain = !show;
  }

  protected onSubmit(): void {
    this.alertService.clear();
    //console.debug('this.form:', this.form);
    if (this.form.invalid) {
      return;
    }
    if (!this.form.value.username || !this.form.value.code || !this.form.value.password || !this.form.value.passwordAgain) {
      return;
    }
    this.isLoading = true;
    this.reCaptchaV3Service.execute(Const.RECAPTCHA_ACTION_PASSWORD_RESET)
      .pipe(first())
      .subscribe({
        next: (token: string) => {
          //console.debug('reCaptchaV3Service token:', token);
          this.authService.passwordReset(this.form.value.username, this.form.value.code, this.form.value.password, token)
            .pipe(first())
            .subscribe({
              next: () => {
                this.alertService.success($localize `Password reset successful`, {keepAfterRouteChange: true});
                return this.router.navigateByUrl(`/${Const.URI_AUTH}/${Const.URI_AUTH_LOGIN}`);
              },
              error: error => {
                this.form.patchValue({
                  password: null,
                  passwordAgain: null
                });
                console.error(error);
                this.alertService.error(error.messageCustom || error);
                this.isLoading = false;
              }
            });
        },
        error: error => {
          console.error(error);
          this.alertService.error($localize `Google reCaptcha error: ${error.messageCustom || error}`);
          this.isLoading = false;
        }
      });
  }

}

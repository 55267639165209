import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Alert, AlertOptions, AlertType} from '@app/_model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private subject = new Subject<Alert>();
  private defaultId = 'default-alert';

  public onAlert(id = this.defaultId): Observable<Alert> {
    return this.subject.asObservable().pipe(filter(alert => alert && alert.id === id));
  }

  public success(message: string, options?: AlertOptions): void {
    console.info('success:', message);
    this.alert(new Alert({autoClose: true, ...options, type: AlertType.Success, message}));
  }

  public info(message: string, options?: AlertOptions): void {
    console.info('info:', message);
    this.alert(new Alert({autoClose: true, ...options, type: AlertType.Info, message}));
  }

  public warn(message: string, options?: AlertOptions): void {
    console.warn('warn:', message);
    this.alert(new Alert({autoClose: true, ...options, type: AlertType.Warning, message}));
  }

  public error(message: string, options?: AlertOptions): void {
    console.error('error:', message);
    this.alert(new Alert({...options, type: AlertType.Error, message}));
  }

  public debug(message: string, options?: AlertOptions): void {
    console.debug('debug:', message);
    this.alert(new Alert({autoClose: true, ...options, type: AlertType.Debug, message}));
  }

  private alert(alert: Alert): void {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }

  public clear(id = this.defaultId): void {
    this.subject.next(new Alert({id}));
  }
}

import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {AccountClientType, Pageable} from "@app/_model";
import {environment} from "@environments/environment";
import {AbstractControl, ValidatorFn} from "@angular/forms";

export class Util {

  private constructor() {
  }

  static isEmpty(obj: any) {
    return obj === undefined
      || obj === null
      || (typeof (obj) === 'number' && isNaN(obj))
      || (typeof (obj) === 'string' && obj === '')
      || (typeof (obj) === 'object' && obj.length === 0);
  }

  static isApiUrl(url: string): boolean {
    return url.startsWith(environment.apiUrl);
  }

  static table2pageable(paginator: MatPaginator, sort: MatSort): Pageable {
    return new Pageable(paginator.pageIndex, paginator.pageSize, sort.active, sort.direction);
  }

  static mailToLink(email: string): string {
    return "mailto:" + email;
  }

  static displayAccountClientType(accountClientType: AccountClientType): string {
    return accountClientType.toLowerCase();
  }

  public static matchValidator(controlName: string, matchingControlName: string, errorKey: string, errorMessage: string): ValidatorFn {
    return (abstractControl: AbstractControl) => {
      const control = abstractControl.get(controlName);
      const matchingControl = abstractControl.get(matchingControlName);
      if (matchingControl!.errors && !matchingControl!.errors?.[errorKey]) {
        return null;
      }
      if (control!.value !== matchingControl!.value) {
        const error = { [errorKey]: errorMessage };
        matchingControl!.setErrors(error);
        return error;
      } else {
        matchingControl!.setErrors(null);
        return null;
      }
    }
  }
}

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthService} from '@app/_service/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      //console.debug('ErrorInterceptor err:', err);
      if ([401].includes(err.status) && this.authService.isLoggedIn) {
        this.authService.logout();
      }
      let message: string;
      if (err.status == 0) {
        message = $localize `Network error`;
      } else if (request.method == 'POST' && request.url == AuthService.API_URI_LOGIN) {
        message = $localize `Authentication failed`;
      } else if (request.method == 'POST' && request.url == AuthService.API_URI_LOGIN_2FA && err.status == 401) {
        message = $localize `Authentication failed`;
      } else if (request.method == 'POST' && request.url == AuthService.API_URI_LOGIN_2FA && err.status == 403) {
        message = $localize `Two-factor authentication failed`;
      } else if (err.status == 403) {
        message = $localize `Access denied`;
      } else if (err.status == 503 || err.status == 504) {
        message = $localize `Backend server unavailable`;
      } else if (err.status >= 500 && err.status < 600) {
        message = $localize `Backend server error`;
      } else if (err.error && err.error.message) {
        message = err.error.message;
      } else if (err.status == 400) {
        message = $localize `Bad query`;
      } else if (err.status == 401) {
        message = $localize `Unauthorized`;
      } else {
        message = err.message || ($localize `Error` || ' (HTTP ' + err.status + ')');
      }
      err.messageCustom = message;
      console.error('ErrorInterceptor error:', err);
      return throwError(() => err);
    }));
  }
}

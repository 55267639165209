export class Alert {
  id?: string;
  type?: AlertType;
  message?: string;
  autoClose?: boolean | number;
  keepAfterRouteChange?: boolean;
  fade?: boolean;

  constructor(
    init?: Partial<Alert>
  ) {
    Object.assign(this, init);
  }
}

export enum AlertType {
  Success = 'SUCCESS',
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR',
  Debug = 'DEBUG'
}

export interface AlertOptions {
  autoClose?: boolean | number;
  keepAfterRouteChange?: boolean;
}

import {AccountClient} from "@app/_model/account.client";
import {BackendData} from "@app/_model/backend.data";

/**
 * Felhasználó
 *
 * @class Account
 */
export class Account extends BackendData {
  login: string;
  ldapDN?: string;
  name: string;
  email: string;
  have2fa: boolean;

  accountClients?: AccountClient[];

  token?: string;
  roles?: RoleType[];
  need2fa?: boolean;

  constructor(
    id: number,
    login: string,
    ldapDN: string | undefined,
    name: string,
    email: string,
    have2fa: boolean,
    inserted: Date | undefined,
    insertedBy: Account | undefined,
    updated: Date | undefined,
    updatedBy: Account | undefined,
    accountClients: AccountClient[] | undefined,
    token: string | undefined,
    roles: RoleType[] | string[] | undefined
  ) {
    super(id, inserted, insertedBy, updated, updatedBy);
    this.login = login;
    this.ldapDN = ldapDN;
    this.name = name;
    this.email = email;
    this.have2fa = have2fa;
    this.accountClients = accountClients;
    this.token = token;
    this.roles = roles?.map(role => RoleType[role as unknown as keyof typeof RoleType]);
  }
}

export enum RoleType {
  User = 'USER',
  Editor = 'EDITOR',
  Admin = 'ADMIN'
}

export interface AccountList {
  count: number;
  items: Account[];
}

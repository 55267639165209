import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AnonymousGuard, AuthGuard, Const} from "@app/_helper";
import {LoginComponent} from './login/login.component';
import {Login2faComponent} from "@app/module/auth/login2fa/login2fa.component";
import {LogoutComponent} from "@app/module/auth/logout/logout.component";
import {PasswordResetRequestComponent} from "@app/module/auth/password-reset-request/password-reset-request.component";
import {PasswordResetComponent} from "@app/module/auth/password-reset/password-reset.component";
import {PersonalComponent} from "@app/module/auth/personal/personal.component";
import {PasswordChangeComponent} from "@app/module/auth/password-change/password-change.component";

const routes: Routes = [
  {
    path: '',
    children: [
      {path: Const.URI_AUTH_LOGIN, component: LoginComponent, canActivate: [AnonymousGuard]},
      {path: Const.URI_AUTH_LOGIN_2FA, component: Login2faComponent, canActivate: [AnonymousGuard]},
      {path: Const.URI_AUTH_LOGOUT, component: LogoutComponent},
      {path: Const.URI_AUTH_PASSWORD_CHANGE, component: PasswordChangeComponent, canActivate: [AuthGuard]},
      {path: Const.URI_AUTH_PASSWORD_RESET_REQUEST, component: PasswordResetRequestComponent, canActivate: [AnonymousGuard]},
      {path: Const.URI_AUTH_PASSWORD_RESET, component: PasswordResetComponent, canActivate: [AnonymousGuard]},
      {path: Const.URI_AUTH_PERSONAL, component: PersonalComponent, canActivate: [AuthGuard]},
      {path: '**', redirectTo: Const.URI_AUTH_LOGIN}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}

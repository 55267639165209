import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AlertService, AuthService} from "@app/_service";
import {Const, Util} from "@app/_helper";
import {first} from "rxjs";

@Component({
  selector: 'app-auth-password-change',
  templateUrl: './password-change.component.html',
  styleUrl: './password-change.component.css',
  standalone: false
})
export class PasswordChangeComponent implements OnInit {

  form!: FormGroup;
  isLoading = false;

  showPasswordNow = false;
  showPasswordNew = false;
  showPasswordNewAgain = false;

  protected readonly Const = Const;

  errors = {
    passwordNow: $localize `Required`,
    passwordNew: $localize `Required, minimum length is 8`,
    passwordNewAgain: $localize `New passwords must match`
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService
  ) {
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      passwordNow: ['', [Validators.required]],
      passwordNew: ['', [Validators.required, Validators.minLength(8)]],
      passwordNewAgain: ['', Validators.required]
    }, {
      validators: Util.matchValidator('passwordNew', 'passwordNewAgain', 'newPasswordsMatch', $localize `New passwords do not match`)
    });
  }

  protected toggleShowPasswordNow(show: boolean): void {
    this.showPasswordNow = !show;
  }

  protected toggleShowPasswordNew(show: boolean): void {
    this.showPasswordNew = !show;
  }

  protected toggleShowPasswordNewAgain(show: boolean): void {
    this.showPasswordNewAgain = !show;
  }

  protected onSubmit(): void {
    this.alertService.clear();
    //console.debug('this.form:', this.form);
    if (this.form.invalid) {
      return;
    }
    if (!this.form.value.passwordNow || !this.form.value.passwordNew || !this.form.value.passwordNewAgain) {
      return;
    }
    this.isLoading = true;
    this.authService.passwordChange(this.form.value.passwordNow, this.form.value.passwordNew)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success($localize `Password change successful`, {keepAfterRouteChange: true});
          return this.router.navigateByUrl(`/${Const.URI_AUTH}/${Const.URI_AUTH_PERSONAL}`);
        },
        error: error => {
          this.form.patchValue({
            passwordNow: null,
            passwordNew: null,
            passwordNewAgain: null
          });
          console.error(error);
          this.alertService.error(error.messageCustom || error);
          this.isLoading = false;
        }
      });
  }

  protected onCancel(): void {
    //console.debug('onCancel()');
    this.router.navigateByUrl(`/${Const.URI_AUTH}/${Const.URI_AUTH_PERSONAL}`);
  }

}

import {Component} from '@angular/core';
import {Account} from "@app/_model";
import {AuthService} from "@app/_service";
import {Const, Util} from "@app/_helper";

@Component({
  selector: 'app-auth-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css'],
  standalone: false
})
export class PersonalComponent {

  constructor(
    private authService: AuthService
  ) {
  }

  protected get account(): Account | null {
    //console.debug("accountValue:", this.authService.accountValue);
    return this.authService.accountValue;
  }

  protected readonly Util = Util;
  protected readonly Const = Const;
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login/login.component';
import {Login2faComponent} from './login2fa/login2fa.component';
import {ReactiveFormsModule} from "@angular/forms";
import {AuthRoutingModule} from "@app/module/auth/auth-routing.module";
import {PersonalComponent} from './personal/personal.component';
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {LogoutComponent} from './logout/logout.component';
import {AutofocusDirective} from "@app/_helper/autofocus.directive";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {PasswordResetRequestComponent} from "@app/module/auth/password-reset-request/password-reset-request.component";
import {PasswordResetComponent} from "@app/module/auth/password-reset/password-reset.component";
import {PasswordChangeComponent} from "@app/module/auth/password-change/password-change.component";

@NgModule({
  declarations: [
    LoginComponent,
    Login2faComponent,
    PasswordResetRequestComponent,
    PasswordResetComponent,
    PersonalComponent,
    PasswordChangeComponent,
    LogoutComponent,
    AutofocusDirective,
  ],
  exports: [
    AutofocusDirective,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
  ]
})
export class AuthModule {
}

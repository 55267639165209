import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';

import {Util} from "@app/_helper/util";
import {AuthService} from '@app/_service/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.debug('JwtInterceptor.intercept(): request:', request, 'account:', this.authService.accountValue);
    if (this.authService.isLoggedIn && Util.isApiUrl(request.url)) {
      const token = this.authService.accountValue?.token;
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request);
  }
}

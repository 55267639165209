import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot} from '@angular/router';

import {AuthService} from '@app/_service/auth.service';
import {Const} from "@app/_helper/const";

@Injectable({providedIn: 'root'})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const returnUrl = (state.url == `/${Const.URI_HOME}`) ? '' : state.url;
    const queryParams: Params = {};
    if (returnUrl) {
      queryParams[Const.PARAM_RETURNURL] = returnUrl;
    }

    if (this.authService.isLoggedIn) {
      if (this.authService.isNeed2fa) {
        console.warn('AuthGuard activated!');
        this.router.navigate([`/${Const.URI_AUTH}/${Const.URI_AUTH_LOGIN_2FA}`], {queryParams: queryParams});
        return false;
      }
      //console.debug('AuthGuard.canActivate OK');
      //console.debug('account:', account);
      return true;
    }

    console.warn('AuthGuard activated!');
    this.router.navigate([`/${Const.URI_AUTH}/${Const.URI_AUTH_LOGIN}`], {queryParams: queryParams});
    return false;
  }
}

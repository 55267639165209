import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'postgresqlDate',
  standalone: false
})
export class PostgresqlDatePipe implements PipeTransform {
  public transform(value: string): string {
    if (value === 'infinity' || value === '-infinity') {
      return '';
    } else {
      return value;
    }
  }
}

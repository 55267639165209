export class DialogResult {

  state: DialogResultState;

  data?: any | undefined;

  constructor(state: DialogResultState, data?: any) {
    this.state = state;
    this.data = data;
  }

  public isOk(): boolean {
    return this.state != DialogResultState.cancel;
  }
}

export enum DialogResultState {
  cancel = 'CANCEL',
  ok = 'OK',
  create = 'CREATE',
  update = 'UPDATE'
}

import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";

import {Util} from "@app/_helper/util";
import {AuthService} from "@app/_service";

@Injectable()
export class ProfileInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.debug('ProfileInterceptor.intercept(): request:', request, 'account:', this.authService.accountValue);
    if (this.authService.isLoggedIn && Util.isApiUrl(request.url)) {
      const accountClient = this.authService.accountClientValue;
      if (accountClient) {
        const clientId = accountClient.client.id;
        request = request.clone({
          setHeaders: {
            "X-Client-ID": String(clientId)
          }
        });
      }
    }
    return next.handle(request);
  }
}

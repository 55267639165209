import {Component, OnInit} from '@angular/core';
import {AlertService, AuthService} from "@app/_service";

@Component({
  selector: 'app-login-logout',
  template: '',
  standalone: false
})
export class LogoutComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    private authService: AuthService
  ) {
  }

  public ngOnInit(): void {
    this.logout();
  }

  protected logout(): void {
    this.authService.logout();
    this.alertService.info($localize `Logout successful`, {keepAfterRouteChange: true});
  }
}

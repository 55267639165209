<div class="app-auth-login">
  <div class="mat-title" i18n>Login</div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <div>
      <mat-form-field>
        <mat-label i18n>Username</mat-label>
        <input matInput type="text" formControlName="username" autocomplete="username" autofocus matInputAutofocus>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label i18n>Password</mat-label>
        <input matInput type="{{showPassword ? 'text' : 'password'}}" formControlName="password" autocomplete="current-password">
        <mat-icon matSuffix style="cursor: pointer;" (click)="toggleShowPassword(showPassword)">{{!showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
      </mat-form-field>
    </div>

    <div class="button-line">
      <button mat-raised-button type="submit" [disabled]="isLoading || !form.valid">
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-1"></span>
        <div i18n>Submit</div>
      </button>
    </div>

    <div class="button-line">
      <button mat-button routerLink="/{{Const.URI_AUTH}}/{{Const.URI_AUTH_PASSWORD_RESET_REQUEST}}" i18n>Password reset</button>
    </div>

  </form>
</div>

<div class="app-auth-password-change">
  <div class="mat-title" i18n>Password change</div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <div>
      <mat-form-field>
        <mat-label i18n>Current password</mat-label>
        <input matInput type="{{showPasswordNow ? 'text' : 'password'}}" formControlName="passwordNow" autocomplete="current-password" autofocus matInputAutofocus>
        <mat-icon matSuffix style="cursor: pointer;" (click)="toggleShowPasswordNow(showPasswordNow)">{{!showPasswordNow ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-error>{{errors.passwordNow}}</mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label i18n>New password</mat-label>
        <input matInput type="{{showPasswordNew ? 'text' : 'password'}}" formControlName="passwordNew" autocomplete="new-password">
        <mat-icon matSuffix style="cursor: pointer;" (click)="toggleShowPasswordNew(showPasswordNew)">{{!showPasswordNew ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-error>{{errors.passwordNew}}</mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label i18n>New password again</mat-label>
        <input matInput type="{{showPasswordNewAgain ? 'text' : 'password'}}" formControlName="passwordNewAgain" autocomplete="new-password">
        <mat-icon matSuffix style="cursor: pointer;" (click)="toggleShowPasswordNewAgain(showPasswordNewAgain)">{{!showPasswordNewAgain ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-error>{{errors.passwordNewAgain}}</mat-error>
      </mat-form-field>
    </div>

    <div class="button-line">
      <button mat-raised-button type="submit" [disabled]="isLoading || !form.valid">
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-1"></span>
        <div i18n>Submit</div>
      </button>
      <button mat-raised-button [disabled]="isLoading" (click)="onCancel()" i18n>Cancel</button>
    </div>

  </form>
</div>

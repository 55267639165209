<div class="app-auth-login2fa">
  <div class="mat-title" i18n>Two-factor authentication</div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <div>
      <mat-form-field>
        <mat-label i18n>Code</mat-label>
        <input matInput type="{{showPassword ? 'text' : 'password'}}" formControlName="code" inputmode="numeric" pattern="[0-9]*" autocomplete="one-time-code" autofocus matInputAutofocus>
        <mat-icon matSuffix style="cursor: pointer;" (click)="toggleShowPassword(showPassword)">{{!showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
      </mat-form-field>
    </div>

    <div class="button-line">
      <button mat-raised-button type="submit" [disabled]="isLoading || !form.valid">
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-1"></span>
        <div i18n>Submit</div>
      </button>
      <button mat-raised-button [disabled]="isLoading" (click)="onCancel()" i18n>Cancel</button>
    </div>

  </form>
</div>

import {environment} from "@environments/environment";

export class Const {

  public static readonly LOCALSTORAGE_PREFIX = 'obiserver-hosting-' + environment.name + '-';

  public static readonly PARAM_RETURNURL = 'returnUrl';

  public static readonly URI_HOME = '';

  public static readonly URI_ABOUT = 'about';

  public static readonly URI_AUTH = 'auth';
  public static readonly URI_AUTH_LOGIN = 'login';
  public static readonly URI_AUTH_LOGIN_2FA = 'login/2fa';
  public static readonly URI_AUTH_LOGOUT = 'logout';
  public static readonly URI_AUTH_PASSWORD_CHANGE = 'password-change';
  public static readonly URI_AUTH_PASSWORD_RESET_REQUEST = 'password-reset-request';
  public static readonly URI_AUTH_PASSWORD_RESET = 'password-reset';
  public static readonly URI_AUTH_PERSONAL = 'personal';

  public static readonly URI_ACCOUNT = 'account';
  public static readonly URI_CLIENT = 'client';

  public static readonly RECAPTCHA_ACTION_PASSWORD_RESET_REQUEST = 'passwordResetRequest';
  public static readonly RECAPTCHA_ACTION_PASSWORD_RESET = 'passwordReset';

}

import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";

import {Util} from "@app/_helper/util";

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {

  constructor(
    @Inject(LOCALE_ID) public locale: string
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.debug('LocaleInterceptor.intercept(): request:', request, 'locale:', this.locale);
    if (Util.isApiUrl(request.url)) {
      request = request.clone({
        setHeaders: {
          "X-Locale": this.locale
        }
      });
    }
    return next.handle(request);
  }
}

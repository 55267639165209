import {SortDirection} from "@angular/material/sort";
import {HttpParams} from "@angular/common/http";

export class Pageable {
  page: number;
  pageSize: number;
  sort: string;
  sortDirection: SortDirection;

  constructor(
    page: number,
    pageSize: number,
    sort: string,
    sortDirection: SortDirection
  ) {
    this.page = page;
    this.pageSize = pageSize;
    this.sort = sort;
    this.sortDirection = sortDirection;
  }

  public getHttpParams(): HttpParams {
    return new HttpParams()
      .set('page', this.page)
      .set('pageSize', this.pageSize)
      .set('sort', this.sort)
      .set('sortDirection', this.sortDirection.toUpperCase());
  }
}

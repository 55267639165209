<div class="app-auth-password-reset">
  <div class="mat-title" i18n>Password reset</div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <input matInput type="text" [hidden]="true" formControlName="username" autocomplete="username">
    <input matInput type="text" [hidden]="true" formControlName="code" autocomplete="off">

    <div>
      <mat-form-field>
        <mat-label i18n>New password</mat-label>
        <input matInput type="{{showPassword ? 'text' : 'password'}}" formControlName="password" autocomplete="new-password" autofocus matInputAutofocus>
        <mat-icon matSuffix style="cursor: pointer;" (click)="toggleShowPassword(showPassword)">{{!showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-error>{{errors.password}}</mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label i18n>New password again</mat-label>
        <input matInput type="{{showPasswordAgain ? 'text' : 'password'}}" formControlName="passwordAgain" autocomplete="new-password">
        <mat-icon matSuffix style="cursor: pointer;" (click)="toggleShowPasswordAgain(showPasswordAgain)">{{!showPasswordAgain ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-error>{{errors.passwordAgain}}</mat-error>
      </mat-form-field>
    </div>

    <div class="button-line">
      <button mat-raised-button type="submit" [disabled]="isLoading || !form.valid">
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-1"></span>
        <div i18n>Submit</div>
      </button>
    </div>

    <div class="button-line">
      <button mat-button routerLink="/{{Const.URI_AUTH}}/{{Const.URI_AUTH_LOGIN}}" i18n>Login</button>
    </div>

  </form>
</div>

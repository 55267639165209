<div>
  <h3 i18n>Personal</h3>
  <mat-list>
    <mat-list-item>
      <span matListItemTitle i18n>Username</span>
      <span matListItemLine>
        {{account?.login}}
      </span>
    </mat-list-item>
    <mat-list-item>
      <span matListItemTitle i18n>Name</span>
      <span matListItemLine>
        {{account?.name}}
      </span>
    </mat-list-item>
    <mat-list-item>
      <span matListItemTitle i18n>E-mail</span>
      <span matListItemLine>
        {{account?.email}}
      </span>
    </mat-list-item>
    <mat-list-item>
      <span matListItemTitle i18n>Options</span>
      <span matListItemLine>
        <button mat-button routerLink="/{{Const.URI_AUTH}}/{{Const.URI_AUTH_PASSWORD_CHANGE}}" i18n>Password change</button>
      </span>
    </mat-list-item>
  </mat-list>
</div>

<div *ngIf="account && account.accountClients && account.accountClients.length">
  <h3 i18n>Clients</h3>
  <mat-list>
    <mat-list-item *ngFor="let accountClient of account.accountClients">
      <span matListItemTitle>
        <b>{{accountClient.client.name}}</b>
      </span>
      <span matListItemLine>
        <span i18n>Role</span>: {{Util.displayAccountClientType(accountClient.type)}}
      </span>
      <span matListItemLine
            *ngIf="accountClient.client.adoszam">
        <span i18n>VAT</span>: {{accountClient.client.adoszam}}
      </span>
      <span matListItemLine
            *ngIf="accountClient.client.email">
        <span i18n>E-mail</span>: <a href="{{Util.mailToLink(accountClient.client.email)}}">{{accountClient.client.email}}</a>
      </span>
    </mat-list-item>
  </mat-list>
</div>

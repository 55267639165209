import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AlertService, AuthService} from "@app/_service";
import {first} from "rxjs";
import {Const} from '@app/_helper';
import {ReCaptchaV3Service} from "ng-recaptcha";

@Component({
  selector: 'app-auth-password-reset-request',
  templateUrl: './password-reset-request.component.html',
  styleUrl: './password-reset-request.component.css',
  standalone: false
})
export class PasswordResetRequestComponent implements OnInit {

  form!: FormGroup;
  isLoading = false;

  protected readonly Const = Const;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) {
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      username: ['', Validators.required]
    });
  }

  protected onSubmit(): void {
    this.alertService.clear();
    if (this.form.invalid) {
      return;
    }
    if (!this.form.value.username) {
      return;
    }
    this.isLoading = true;
    this.reCaptchaV3Service.execute(Const.RECAPTCHA_ACTION_PASSWORD_RESET_REQUEST)
      .pipe(first())
      .subscribe({
        next: (token: string) => {
          //console.debug('reCaptchaV3Service token:', token);
          this.authService.passwordResetRequest(this.form.value.username, token)
            .pipe(first())
            .subscribe({
              next: () => {
                this.alertService.success($localize `Password reset request successful`, {keepAfterRouteChange: true});
                return this.router.navigateByUrl(`/${Const.URI_AUTH}/${Const.URI_AUTH_LOGIN}`);
              },
              error: error => {
                this.form.reset();
                console.error(error);
                this.alertService.error(error.messageCustom || error);
                this.isLoading = false;
              }
            });
        },
        error: error => {
          console.error(error);
          this.alertService.error($localize `Google reCaptcha error: ${error.messageCustom || error}`);
          this.isLoading = false;
        }
      });
  }

}

import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, first, map, Observable} from "rxjs";
import {environment} from '@environments/environment';
import {Account, AccountClient, RoleType} from "@app/_model";
import {Const} from "@app/_helper";
import {LoginRequest} from "@app/_model/payload/login.request";
import {Login2faRequest} from "@app/_model/payload/login2fa.request";
import {PasswordResetRequestRequest} from "@app/_model/payload/password.reset.request.request";
import {PasswordResetRequest} from "@app/_model/payload/password.reset.request";
import {PasswordChangeRequest} from "@app/_model/payload/password.change.request";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private static readonly API_URI_PREFIX = `${environment.apiUrl}/auth`;
  public static readonly API_URI_LOGIN = `${AuthService.API_URI_PREFIX}/login`;
  public static readonly API_URI_LOGIN_2FA = `${AuthService.API_URI_PREFIX}/login/2fa`;
  public static readonly API_URI_UPDATE = `${AuthService.API_URI_PREFIX}/update`;
  public static readonly API_URI_PASSWORD_CHANGE = `${AuthService.API_URI_PREFIX}/password-change`;
  public static readonly API_URI_PASSWORD_RESET_REQUEST = `${AuthService.API_URI_PREFIX}/password-reset-request`;
  public static readonly API_URI_PASSWORD_RESET = `${AuthService.API_URI_PREFIX}/password-reset`;

  private static readonly LOCALSTORAGE_KEY_ACCOUNT = Const.LOCALSTORAGE_PREFIX + 'auth-account';
  private static readonly LOCALSTORAGE_KEY_ACCOUNT_CLIENT_PREFIX = Const.LOCALSTORAGE_PREFIX + 'account-client-';

  /**
   * Az aktuálisan bejelentkezett {@link Account}.
   * @private
   */
  private accountSubject: BehaviorSubject<Account | null>;
  /**
   * Az aktuálisan bejelentkezett {@link Account}.
   * @public
   */
  public account: Observable<Account | null>;

  /**
   * Az aktuálisan bejelentkezett {@link Account} aktuálisan kiválasztott ({@link ClientPicker}) {@link AccountClient}-ja.
   */
  public accountClientSubject?: BehaviorSubject<AccountClient | null>;
  /**
   * Az aktuálisan bejelentkezett {@link Account} aktuálisan kiválasztott ({@link ClientPicker}) {@link AccountClient}-ja.
   */
  public accountClient?: Observable<AccountClient | null>;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.accountSubject = new BehaviorSubject(JSON.parse(localStorage.getItem(AuthService.LOCALSTORAGE_KEY_ACCOUNT)!));
    this.account = this.accountSubject.asObservable();
    this.accountClientSubject = new BehaviorSubject(this.JSONparseLocalStorageAccountClient);
    this.accountClient = this.accountClientSubject.asObservable();
    this.accountSubject.subscribe(account => {
      //console.log("accountClientSubject.next() for account:", account);
      let accountClient = this.JSONparseLocalStorageAccountClient;
      if (accountClient == undefined) {
        if (account?.accountClients != undefined && account.accountClients.length > 0) {
          accountClient = account?.accountClients[0]; // TODO a 0. elem helyett a default kell ide!
        }
      }
      this.setAccountClient(accountClient);
    });
  }

  private get localStorageKeyAccountClient(): string | null {
    const suffix = this.accountValue?.id;
    if (suffix == undefined) {
      return null;
    }
    //console.debug("localStorageKeyAccountClient(): suffix:", suffix);
    return AuthService.LOCALSTORAGE_KEY_ACCOUNT_CLIENT_PREFIX + suffix;
  }

  private get JSONparseLocalStorageAccountClient(): AccountClient | null {
    const key = this.localStorageKeyAccountClient;
    if (key == undefined) {
      return null;
    }
    return JSON.parse(localStorage.getItem(key)!);
  }

  /**
   * Az aktuálisan bejelentkezett {@link Account}.
   */
  public get accountValue(): Account | null {
    return this.accountSubject.value;
  }

  public get isLoggedIn(): boolean {
    return !!(this.accountValue && this.accountValue.token);
  }

  public get isNeed2fa(): boolean {
    return !!(this.isLoggedIn && this.accountValue && this.accountValue.token && this.accountValue.need2fa === true);
  }

  /**
   * Az aktuálisan bejelentkezett {@link Account} aktuálisan kiválasztott ({@link ClientPicker}) {@link AccountClient}-ja.
   */
  public get accountClientValue(): AccountClient | null | undefined {
    return this.accountClientSubject?.value;
  }

  public accountHasRole(role: RoleType): boolean {
    if (!this.accountValue || !this.accountValue.roles) {
      return false;
    }
    return this.accountValue.roles.includes(role);
  }

  public login(username: string, password: string): Observable<Account> {
    let data: LoginRequest = {username, password};
    return this.http.post<Account>(`${AuthService.API_URI_LOGIN}`, data)
      .pipe(map(account => {
        console.info('login successful');
        // console.debug('account:', account);
        // console.debug('account:', typeof account, account);
        // console.debug('account.roles:', account.roles);
        // account.roles?.map(role => {
        //  console.debug('map->role:', typeof role, role);
        // });
        this.storeAccount(account);
        return account;
      }));
  }

  public login2fa(code: string): Observable<Account> {
    let data: Login2faRequest = {code};
    return this.http.post<Account>(`${AuthService.API_URI_LOGIN_2FA}`, data)
      .pipe(map(account => {
        console.info('login2fa successful');
        // console.debug('account:', account);
        this.storeAccount(account);
        return account;
      }));
  }

  private storeAccount(data: Account): void {
    //console.debug('data:', data);
    localStorage.setItem(AuthService.LOCALSTORAGE_KEY_ACCOUNT, JSON.stringify(data));
    this.accountSubject.next(data);
  }

  public update(): void {
    this.http.get<Account>(`${AuthService.API_URI_UPDATE}`)
      .pipe(first())
      .subscribe({
        next: (account) => {
          console.info('update successful');
          //console.debug('account:', account);
          this.storeAccount(account);
        },
        error: error => {
          console.error(error);
        }
    });
  }

  public logoutSilent(): void {
    this.setAccountClient(null);
    localStorage.removeItem(AuthService.LOCALSTORAGE_KEY_ACCOUNT);
    this.accountSubject.next(null);
  }

  public logout(): Promise<boolean> {
    this.logoutSilent();
    console.info('logout successful');
    return this.router.navigateByUrl(`/${Const.URI_AUTH}/${Const.URI_AUTH_LOGIN}`);
  }

  public setAccountClient(accountClient: AccountClient | null): void {
    const key = this.localStorageKeyAccountClient;
    if (key == undefined) {
      return;
    }
    if (accountClient == undefined) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(accountClient));
    }
    //console.debug("setAccountClient(): this.accountClientSubject:", this.accountClientSubject);
    this.accountClientSubject?.next(accountClient);
  }

  public passwordChange(passwordNow: string, passwordNew: string): Observable<void> {
    if (!this.accountValue) {
      throw new Error(`Not logged`);
    }
    let username = this.accountValue.login;
    let data: PasswordChangeRequest = {username, passwordNow, passwordNew};
    return this.http.post(`${AuthService.API_URI_PASSWORD_CHANGE}`, data)
      .pipe(map(() => {
        console.info('password-change successful');
      }));
  }

  public passwordResetRequest(username: string, response: string): Observable<void> {
    let data: PasswordResetRequestRequest = {username, response};
    return this.http.post(`${AuthService.API_URI_PASSWORD_RESET_REQUEST}`, data)
      .pipe(map(() => {
        console.info('password-reset-request successful');
      }));
  }

  public passwordReset(username: string, code: string, password: string, response: string): Observable<void> {
    let data: PasswordResetRequest = {username, code, password, response};
    return this.http.post(`${AuthService.API_URI_PASSWORD_RESET}`, data)
      .pipe(map(() => {
        console.info('password-reset successful');
      }));
  }

}
